// HEADER BANNER ONE CSS
.rts-hero {
    position: relative;

    &__one {
        background: var(--banner-one-bg);
        padding-bottom: 200px;
        padding-top: 300px;
        transition: 1s ease;

        &::before {}

        @media(max-width:450px) {
            padding-top: 210px;
            padding-bottom: 120px;
        }
    }

    &.banner-style-home-one {
        position: relative;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            content: "";
            background-image: url(../images/banner/banner_home_one_dotted.svg);
            background-position: center;
            opacity: .4;
            background-repeat: repeat;
        }
    }

    &__blur-area {
        width: 348px;
        height: 458px;
        background: var(--color-primary);
        border-radius: 458px;
        filter: blur(200px);
        position: absolute;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }

    &__content {
        h6 {
            border: 1px solid var(--body-bg);
            border-radius: 20px;
            padding: 8px 12px;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            color: var(--body-bg);
            font-size: 18px;
            font-family: var(--font-secondary);
            margin-bottom: 25px;
            line-height: 100%;

            @media(max-width:991px) {
                display: flex;
                max-width: max-content;
            }

            @media(max-width:450px) {
                font-size: 14px;
                margin-bottom: 25px;
            }
        }

        h1 {
            font-size: 66px;
            font-weight: var(--bold);
            color: var(--body-bg);
            display: inline-block;
            text-transform: capitalize;
            line-height: var(--lh-h1);
            margin-bottom: 25px;
            position: relative;
            z-index: 1;

            @media(max-width:1200px) and (min-width:991px) {
                font-size: 54px;
            }

            @media(max-width:991px) {
                max-width: 550px;
            }

            @media(max-width:576px) {
                font-size: 50px;
                line-height: 1.12;
            }

            @media(max-width:450px) {
                font-size: 34px;
                line-height: 1.2;
                margin-bottom: 25px;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 35%;
                height: 16px;
                width: 200px;
                background: var(--color-third);
                z-index: -1;

                @media(max-width:500px) {
                    display: none;
                }
            }
        }

        .description {
            color: var(--body-bg);
            font-weight: 500;
            border: none;
            line-height: 28px;
            margin-bottom: 45px;
            display: block;
            font-size: 18px;
            font-family: var(--font-secondary);
            font-weight: 500;

            @media(max-width:450px) {
                margin-bottom: 30px;
            }
        }

        &--group {
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 30px;

            @media(max-width:500px) {
                gap: 30px;
            }

            .primary__btn {
                padding: 18px 30px;
                font-family: var(--font-secondary);
                font-weight: 600;
                color: var(--color-secondary);

                @media(max-width:500px) {
                    padding: 14px 20px;
                }
            }

            .plan__btn {
                color: var(--body-bg);
                font-weight: var(--semibold);
            }

        }

        p {
            color: var(--body-bg);
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: var(--medium);

            @include mq($xs) {
                flex-wrap: wrap;
                justify-content: center;
            }

            span {
                color: var(--color-third);
                font-weight: var(--semibold);
            }

            a {
                color: var(--color-third);
                transition: var(--transition);
                text-decoration: underline;
                text-decoration-color: transparent;
                font-weight: 600;
                font-family: var(--font-secondary);
                font-size: 18px;

                &:hover {
                    text-decoration-color: var(--color-third);
                }
            }
        }
    }

    &__images {
        margin-bottom: -10%;
        z-index: 1;

        @media(max-width:991px) {
            margin-bottom: 0;
            margin-top: 100px;
        }

        .rts-hero-main {
            text-align: center;

            .image-main {
                @media(max-width:768px) {
                    width: 70%;
                    margin: auto;
                }
            }

            .hero-shape {
                position: absolute;

                &.one {
                    bottom: -7%;
                    left: 0;
                    z-index: -1;

                    @media(max-width:991px) {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        &--shape {
            .one {
                top: 0%;
                position: absolute;
                left: 0%;
            }

            .two {
                position: absolute;
                bottom: 10%;
                left: 10%;

                @media(max-width:576px) {
                    left: 5%;
                }
            }

            .three {
                position: absolute;
                right: 8%;
                top: 0%;

                @media(max-width:576px) {
                    right: 5%;
                }
            }

            .four {
                position: absolute;
                right: -2%;
                bottom: 25%;

                @media(max-width:991px) {
                    right: 0;
                }

                @media(max-width:576px) {
                    bottom: 10%;
                }
            }

            @media(max-width:576px) {

                .one,
                .two,
                .three,
                .four {
                    animation: none;
                }
            }
        }
    }

}

// HEADER BANNER TWO CSS
.rts-hero-two {
    overflow: hidden;
    position: relative;
    padding: 270px 0 250px;

    @include mq($xs) {
        padding: 210px 0 215px;
    }

    .shape {
        &__one {
            position: absolute;
            top: 10px;
            left: 0;
        }
    }

    &::before {
        @include ph;
        @include background;
        background-image: url(../images/banner/two/shape__one.png);

    }

    &::after {
        position: absolute;
        left: 0;
        content: '';
        height: 100%;
        width: 100%;
        bottom: 0;
        @include background;
        background-image: url(../images/banner/two/shape__two.svg);
    }

    &__bg {
        background: linear-gradient(259deg, #006CF5 7.09%, #0559D9 71.14%);
    }

    &__content {
        position: relative;
        z-index: 1;

        .title {
            position: relative;
            font-size: 66px;
            font-weight: var(--bold);
            color: var(--body-bg);
            line-height: 76px;

            @include mq($xs) {
                font-size: 45px;
                line-height: 1.12;
            }

            span {
                font-weight: var(--semibold);
                color: var(--color-third);
            }

            .stroke__line {
                position: absolute;
                bottom: -15%;
                right: 38%;
            }
        }

        &--btn {
            margin-top: 60px;
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
        }

        .terms {
            margin-top: 25px;

            svg {
                margin-right: 5px;
            }

            span {
                color: var(--body-bg);

            }
        }
    }

    &__images {
        position: relative;
        z-index: 3;

        &.mobile-margin-top-100 {
            @include mq($sm) {
                margin-top: 130px;
            }
        }

        .shape-image {
            position: absolute;

            &.one {
                top: -15%;
                right: 35%;
            }

            &.two {
                top: 44.5%;
                left: 1px;
                animation: ping-pong 8s linear infinite;
            }

            &.three {
                bottom: 0;
                right: 33.2%;
                animation: ping-pong 8s linear infinite;
            }

            &.four {
                bottom: 10.4%;
                right: 11.6%;
                animation: bottom-top2 3s linear infinite;
            }

            &.five {
                bottom: 30%;
                right: 1%;
                animation: ping-pong 8s linear infinite;
            }
        }
    }
}

.rts-hero-three {
    background: linear-gradient(259deg, #006CF5 7.09%, #0559D9 71.14%);
    padding: 275px 0 250px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @include mq($sm) {
        padding: 150px 0;
    }

    &.domain-checker-padding {
        padding: 255px 0 170px;
    }

    .rts-hero__content {
        text-align: center;

        h1 {
            font-size: 55px;
            line-height: 1.15;
            margin-bottom: 25px;

            @include mq($xs) {
                font-size: 35px;
            }

            &::before {
                display: none;
            }
        }

        .description {
            display: block;
            margin-bottom: 40px;
            max-width: 100%;
        }

        form {
            width: 830px;

            @include mq($md) {
                width: 600px;
            }

            @include mq($sm) {
                width: 100%;
            }

            margin: auto;
            position: relative;

            input {
                background: var(--color-white);
                height: 56px;
                border-radius: 6px;

                &::placeholder {
                    color: #787878;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 46px;
                }
            }

            .select-button-area {
                display: flex;
                align-items: center;
                width: max-content;
                position: absolute;
                top: 0;
                right: 0;

                .nice-select {
                    background: #F4F4F4;
                    height: 56px;
                    line-height: 53px;
                    border-radius: 0;
                    border: none;

                    &:focus {
                        border: none;
                    }
                }

                button {
                    background: #FFC107;
                    height: 56px;
                    line-height: 56px;
                    padding: 0 40px;
                    border-radius: 0 6px 6px 0;

                    @include mq($xs) {
                        padding: 0 25px;
                    }
                }
            }
        }

        .banner-content-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 30px;
            flex-wrap: wrap;

            .tag-list {
                display: flex;
                padding: 0;
                margin: 0;
                gap: 10px;
                flex-wrap: wrap;

                @include mq($xs) {
                    justify-content: center;
                }

                li {
                    list-style: none;
                    border-radius: 3px;
                    background: rgba(255, 255, 255, 0.10);
                    padding: 4px 9px;
                    display: flex;
                    align-items: center;
                    gap: 7px;

                    span {
                        color: var(--color-white);
                    }
                }
            }
        }

        &.domain {
            .description {
                margin: auto;
                margin-bottom: 40px;
            }
        }
    }

    .hero-image-big {
        position: absolute;
        bottom: -50%;
        right: 80px;
        z-index: -1;

        @media(max-width:768px) {
            display: none;
        }
    }

    .banner-shape-area {
        img {
            position: absolute;
            z-index: -2;

            &.one {
                top: 20%;
                left: 6%;

                @include mq($md) {
                    left: 50%;
                    transform: translate(-50%);
                }

                @media(max-width:768px) {
                    display: none;
                }
            }

            &.two {
                bottom: 20%;
                left: 6%;

                @include mq($lg) {
                    bottom: 10%;
                }

                @media(max-width:768px) {
                    display: none;
                }
            }

            &.three {
                top: 0;
                left: 0;
            }
        }
    }
}

// BANNER FOUR STYLE CSS
.rts-hero-four {
    padding: 220px 0;

    @include mq($sm) {
        padding: 100px 0;
    }

    &.rts-hero__four {
        @include background;
        position: relative;
        z-index: 2;

        &::after {
            @include ph;
            background-image: url(../images/banner/banner-hero-four-bg.svg);
            @include background;
            z-index: -1;
        }

        &::before {
            @include ph;
            background: var(--banner-four-gradient);
            z-index: -1;
        }
    }

    &__content {
        color: var(--color-white);

        .offer {
            text-transform: capitalize;
            font-size: 18px;
            font-family: var(--font-secondary);
            font-weight: var(--semibold);
            margin-bottom: 10px;

            .off {
                color: var(--color-third);
            }
        }

        .banner__title {
            font-size: 60px;
            font-weight: var(--bold);
            line-height: 70px;
            font-family: var(--font-primary);
            display: inline-block;
            margin-bottom: 25px;

            @include mq($xs) {
                font-size: 35px;
                line-height: 50px;
            }
        }

        .description {
            max-width: 460px;
        }

        .banner-buttons {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
        }

        .feature {
            margin-top: 30px;
            margin-bottom: 40px;

            &__list {
                display: flex;
                flex-direction: column;
                gap: 15px;
                @include ul;

                .feature__item {
                    position: relative;
                    margin-left: 25px;
                    color: #fff;
                    font-family: var(--font-secondary);
                    font-weight: var(--medium);

                    &::before {
                        position: absolute;
                        left: -25px;
                        top: 50%;
                        transform: translateY(-50%);
                        content: '\f00c';
                        font-family: var(--fontawesome);
                        height: 16px;
                        width: 16px;
                        font-size: 10px;
                        font-weight: 800;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background: var(--color-third);
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

// feature
.feature {
    margin-top: 30px;
    margin-bottom: 40px;

    &.style-two {
        li {
            color: var(--btc);
            font-weight: 400;

            &::before {
                background: transparent;
                font-size: 16px;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include ul;

        .feature__item {
            position: relative;
            margin-left: 25px;
            color: #fff;
            font-family: var(--font-secondary);
            font-weight: var(--medium);

            &::before {
                position: absolute;
                left: -25px;
                top: 6px;
                content: '\f00c';
                font-family: var(--fontawesome);
                height: 16px;
                width: 16px;
                line-height: 16px;
                font-size: 10px;
                font-weight: 800;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: var(--color-third);
                color: var(--color-primary);
            }
        }
    }
}

// BANNER SIX STYLE
.rts-hero-banner {
    &.banner-six {
        @include background;
        position: relative;
        z-index: 2;

        &::before {
            @include ph;
            background: linear-gradient(270deg, rgba(1, 10, 29, 0.00) 22.92%, rgba(1, 5, 13, 0.80) 63.62%);
            z-index: -1;
        }

        &::after {
            background-image: url(../images/banner/banner__six__bg.svg);
            content: "";
            position: absolute;
            left: 0;
            height: 100%;
            top: 0;
            width: 100%;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }
}
.hero-banner-slide .slider-arrow{
    opacity: 0;
    transition: var(--transition);
}
.hero-banner-slide:hover .slider-arrow {
	opacity: 1;
}
.swiper-slide {
    .banner-content {
        opacity: 0;
    }

    .banner-top {
        opacity: 0;
        animation: none;
        transition-delay: 1s;
    }

    .banner-title {
        opacity: 0;
        animation: none;
        transition-delay: 1.5s;
    }

    .description {
        opacity: 0;
        animation: none;
        transition-delay: 2s;
    }

    .banner-btn {
        opacity: 0;
        animation: none;
        transition-delay: 2.2s;
    }
}

// BANNER SIX SLIDER
.swiper-slide-active .banner-content {
    opacity: 1;
}

.swiper-slide-active .banner-top {
    animation: fadeInUp 1s;
    animation-delay: 1s;
    opacity: 1;
}

.swiper-slide-active .banner-title {
    animation: fadeInUp 1s;
    animation-delay: 1.5s;
    opacity: 1;
}

.swiper-slide-active .description {
    animation: fadeInUp 1s;
    animation-delay: 2s;
    opacity: 1;
}

.swiper-slide-active .banner-btn {
    animation: fadeInUp 1s;
    animation-delay: 2.2s;
    opacity: 1;
}

.banner-six {
    position: relative;
    padding-top: 315px;
    padding-bottom: 220px;
    z-index: 2;

    @media(max-width:768px) {
        padding-top: 250px;
        padding-bottom: 150px;
    }

    @media(max-width:576px) {
        padding-top: 200px;
        padding-bottom: 120px;
    }

    @media(max-width:450px) {
        padding-top: 150px;
        padding-bottom: 100px;
    }

    @include background;

    .banner-content {
        max-width: 560px;

        @include mq($xs) {
            max-width: 100%;
        }

        .banner-top {
            color: var(--color-white);
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 20px;
        }

        .banner-title {
            font-size: 80px;
            font-weight: var(--bold);
            line-height: 90px;
            color: var(--color-white);

            @media(max-width:768px) {
                font-size: 75px;
                line-height: 85px;
            }

            @media(max-width:576px) {
                font-size: 58px;
                line-height: 70px;
            }

            @media(max-width:450px) {
                font-size: 42px;
                line-height: 54px;
            }

            span {
                color: var(--color-third);
            }
        }

        p {
            font-size: 20px;
            font-family: var(--font-secondary);
            color: #CACACA;
            line-height: 30px;
            margin-bottom: 0;
        }

        .banner-btn {
            padding-top: 40px;
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            @media(max-width:450px) {
                padding-top: 30px;
            }

            .btn__long {
                max-width: 160px;
                padding: 16px 0;
                min-width: 160px;
                text-align: center;
                font-family: var(--font-primary);
                font-weight: 400;
            }
        }
    }
}

// SHARED HOSTING TEMPLATE CSS
// COMMON BANNER FOR PAGE TEMPLATES 
.rts-hosting-banner {
    &.banner-default-height {
        max-height: 700px;
        min-height: 700px;
        display: flex;
        align-items: center;

        @include mq($md) {
            max-height: 100%;
            min-height: 100%;
        }
    }

    &.rts-hosting-banner-bg {
        padding: 225px 0 130px 0;
        position: relative;
        z-index: 1;

        &:before {
            @include ph;
            background: var(--banner-most-used-bg);
        }

        &::after {
            @include ph;
            @include background;
            background-image: url(../images/banner/shared/banner__bg.svg);
        }
    }

    .banner-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        @include mq($sm) {
            flex-direction: column;
            gap: 50px;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        color: var(--color-white);
        max-width: 450px;

        .starting__price {
            background: rgba(255, 255, 255, 0.10);
            border-radius: 4px;
            padding: 8px 10px;
            font-size: 14px;
            font-family: var(--font-secondary);
            color: var(--color-white);
            font-weight: 500;
            text-transform: capitalize;
        }

        .banner-title {
            font-size: 55px;
            font-weight: 700;
            margin: 20px 0 25px 0;

            @include mq($md) {
                max-width: 575px;
                font-size: 45px;
            }

            @media screen and (min-width:767px) and (max-width:900px) {
                font-size: 40px;
            }

            @include mq($xs) {
                font-size: 30px;
            }
        }

        .slogan {
            font-family: var(--font-secondary);
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 0;

            @include mq($md) {}

            @media screen and (min-width:767px) and (max-width:900px) {
                font-size: 18px;
            }
        }

        .hosting-feature {
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
            margin-top: 30px;

            @include mq($md) {
                gap: 20px;
            }

            &__single {
                display: flex;
                gap: 15px;
                max-width: 200px;
                align-items: center;

                @include mq($md) {
                    max-width: 250px;
                }

                @include mq($sm) {
                    max-width: 200px;
                }

                @include mq($xs) {
                    max-width: 100%;
                }

                .icon-image {
                    height: 40px;
                    min-width: 40px;
                }

                .feature-text {
                    font-size: 14px;
                    font-family: var(--font-secondary);
                    margin-bottom: 0;
                }
            }
        }

        .hosting-action {
            margin-top: 35px;
            display: flex;
            gap: 20px;
            align-items: center;
            flex-wrap: wrap;

            .btn__two {
                text-align: center;
                font-weight: 600;
                font-family: var(--font-secondary);
                padding: 17px 32px;
                line-height: 1;
                min-width: 160px;
            }

            .btn__white {
                color: #fff;
                font-family: var(--font-secondary);
                font-weight: 500;
                position: relative;
                overflow: hidden;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-white);
                    left: 0;
                    transition: var(--transition);
                }

                &::before {
                    position: absolute;
                    left: -50%;
                    width: 100%;
                    content: '';
                    height: 1px;
                    background: var(--color-third);
                    transition: all 4s;
                    opacity: 0;
                    bottom: 0;
                }

                &:hover::before {
                    opacity: 1;
                    width: 100%;
                    left: 105%;
                    z-index: 1;
                }
            }
        }

        &.business-mail-banner {
            max-width: 520px;
        }
    }

    // &__image {
    //     position: absolute;
    //     right: 0;
    //     bottom: 50%;
    //     transform: translateY(50%);
    //     z-index: 1;
    //     @include mq($md){
    //         width: 40%;
    //     }
    //     @include mq($sm){
    //         position: unset;
    //         transform: none;
    //         width: 100%;
    //     }
    //     @include mq($xs){
    //         display: none;
    //     }
    //     .shape-image{
    //         position: absolute;
    //         z-index: -1;
    //         &.one{
    //             top: 0;
    //             left: 0;
    //         }
    //         &.two{
    //             top: 46%;
    //             left: 60px;
    //             @include mq($sm){
    //                 top: 25%;
    //             }            
    //         }
    //     }
    // }
    &__image {
        position: relative;
        z-index: 1;

        @include mq($sm) {
            margin-top: 50px;
        }

        .shape-image {
            position: absolute;
            z-index: -1;

            &.one {
                top: 0;
                left: 0;
            }

            &.two {
                top: 46%;
                left: 60px;

                @include mq($sm) {
                    top: 25%;
                }
            }
        }

        &.business-mail__image {
            .shape-image {
                position: absolute;
                right: 22.6%;
                top: 9.7%;
                left: unset;
                z-index: 1;
                animation: zoom-shake 3s linear infinite;
            }
        }

        &.partner {
            .shape__image {
                .shape__image--one {
                    top: 0;
                    left: 10%;
                    animation: rotateIt2 20s linear infinite;
                }

                .shape__image--two {
                    top: 12%;
                    left: 0%;
                    animation: rotateIt 20s linear infinite;
                }

                .shape__image--three {
                    top: 4%;
                    left: 37%;
                }

                .shape__image--four {
                    top: 3%;
                    right: 28%;
                }

                .shape__image--five {
                    top: 4%;
                    right: 0%;
                }
            }
        }
    }

    .shape__image {
        img {
            position: absolute;

        }

        &--one {
            position: absolute;
            bottom: 0;
        }

        &--two {
            right: 0;
            top: 0;

        }

        &--three {
            right: 0;
            bottom: 0;
        }
    }
}

.rts-hosting-banner__image.vps {
    .shape-image {
        position: absolute;

        &.one {
            left: 20px;
            top: -30px;

            @include mq($sm) {
                display: block;
            }

            @include mq($xs) {
                display: none;
            }
        }
    }
}

.rts-hosting-banner__content {
    &.about__banner {
        max-width: 600px;

        .slogan {
            width: 500px;
        }
    }

    &.support__banner {
        max-width: 525px;

        .hosting-action {
            .btn__white {
                img {
                    margin-right: 15px;
                }
            }
        }
    }

    &.blog__banner {
        max-width: 620px;
    }

    &.contact__banner {
        max-width: 600px;
    }

}

.rts-hosting-banner__image {
    &.about {
        z-index: 1;
        border-radius: 10px;

        img {
            border-radius: 10px;
        }

        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0.20);
            top: -30px;
            right: -30px;
            z-index: -1;
        }
    }

    &.support {

        @include mq($sm) {
            display: none;
        }

        .shape {
            position: absolute;

            &.one {
                top: -70px;
                left: -50px;
            }

            &.two {
                bottom: -70px;
                right: -50px;
            }

            &.three {
                bottom: -5px;
                left: -60px;
            }

            &.four {
                bottom: -80px;
                left: -15px;
            }

            &.five {
                top: -75px;
                right: 40px;
            }

            &.six {
                top: -30px;
                right: -30px;
            }

            &.seven {
                top: 45px;
                right: -60px;
            }
        }
    }

    &.blog {
        right: 100px;

        .shape {
            position: absolute;

            &.one {
                top: -50px;
                right: -150px;
            }

            &.two {
                bottom: 70px;
                right: -70px;
            }
        }
    }

    &.contact {
        right: 0;
        top: 15%;

        @include mq($md) {
            display: none;
        }

        .shape {
            position: absolute;

            &.one {
                top: -55px;
                left: -50px;
                z-index: -1;
            }

            &.two {
                top: -90px;
                left: 50px;
                z-index: -1;
            }

            &.three {
                top: -105px;
                left: -50px;
                z-index: -1;
                animation: shake-br 8s linear infinite;
            }

            &.four {
                top: -120px;
                right: 10px;
                z-index: -2;
            }
        }
    }

    &.faq {
        right: 50px;

        @include mq($xs) {
            right: 0;
        }

        .shape {
            position: absolute;

            &.one {
                top: -15px;
                right: 85px;
                z-index: 1;
            }

            &.two {
                top: -35px;
                right: 145px;
                z-index: 1;
            }

            &.three {
                top: -59px;
                right: 0;
            }

            &.four {
                top: -45px;
                left: 20px;
                z-index: -1;
            }
        }
    }
}

.rotate-one {
    animation: rotateIt 20s linear infinite;
}

.rotate-two {
    animation: rotateIt2 20s linear infinite;
}

.shake-br {
    animation: shake-br 20s linear infinite;
}

.shake-tl {
    animation: shake-tl 20s linear infinite;
}

.shake-lr {
    animation: shake-lr 5s 4s linear infinite;
}

.rts-hero-five {
    background: linear-gradient(258deg, #0774FF 0.95%, #033AA7 100%);
    padding: 140px 0 190px;
    position: relative;
    z-index: 1;

    @include mq($xs) {
        padding: 150px 0;
    }

    @include mq($lg) {
        padding: 150px 0;
    }

    .banner-content {
        @include mq($xs) {
            max-width: 100%;
        }

        .banner-top {
            color: var(--color-white);
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 20px;
        }

        .banner-title {
            font-size: 96px;
            font-weight: var(--bold);
            line-height: 1.15;
            color: var(--color-white);

            @include mq($xs) {
                font-size: 45px;
            }

            @include mq($xl) {
                font-size: 80px;
            }

            @include mq($sm) {
                font-size: 50px;
            }

            span {
                color: var(--color-third);
                position: relative;

                img {
                    position: absolute;
                    bottom: 0;
                    right: 60px;

                    @include mq($xs) {
                        display: none;
                    }
                }
            }
        }

        p {
            font-size: 20px;
            font-family: var(--font-secondary);
            color: var(--color-white);
            line-height: 30px;
            margin-bottom: 0;

            @include mq($xl) {
                max-width: 550px;
            }
        }

        .banner-btn {
            padding-top: 45px;
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
            align-items: center;

            .btn__two {
                background: var(--color-third);
            }

            .btn__long {
                padding: 16px 0;
                min-width: 160px;
                border-radius: 4px;
                text-align: center;
            }

            .video-btn {
                display: flex;
                align-items: center;
                gap: 15px;

                .icon {
                    display: block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 50%;
                    border: 1px solid var(--color-white);
                    color: var(--color-white);

                    i {
                        font-size: 20px;
                    }
                }

                .disc {
                    color: var(--color-white);
                    font-weight: 500;
                }
            }
        }
    }

    .rts-hero-image {
        position: relative;

        @include mq($md) {
            margin-top: 50px;
        }
    }

    .shape {
        position: absolute;
        animation: tada 10s ease infinite;

        &.one {
            top: -45px;
            left: 10px;
        }

        &.two {
            top: -40px;
            right: -5px;

            @include mq($md) {
                top: -44px;
                right: 185px;
            }
        }

        &.three {
            bottom: -75px;
            right: 160px;

            @include mq($lg) {
                right: 100px;
            }

            @include mq($md) {
                right: 350px;
            }
        }
    }
}

.rts-hero-shape-area {
    img {
        position: absolute;
        z-index: -1;
    }

    .shape-one {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .shape-two {
        top: 0;
        right: 0;
    }

    .shape-three {
        bottom: 0;
        right: 0;
    }

    .shape-four {
        top: 0;
        left: 0;
    }

    .shape-five {
        bottom: 0;
        left: 0;
    }
}

.rts-feature-section,
.rts-section-content {
    max-width: 430px;

    @include mq($xs) {
        max-width: 100%;
    }

    &.dedicated {
        max-width: 550px;
    }

    &.rts-section-content {
        max-width: 480px;

    }

    h2.title {
        font-size: 48px;
        color: var(--color-secondary);
        font-weight: 700;
        margin-bottom: 30px;
        line-height: 58px;

        @include mq($lg) {
            line-height: 1.2;
            font-size: 30px;
        }

        @include mq($md) {
            font-size: 48px;
        }

        @include mq($xs) {
            font-size: 35px;
        }
    }

    p,
    .description {
        font-family: var(--font-secondary);
        font-weight: 400;
        margin-bottom: 0;
        max-width: 390px;

        &.description {
            max-width: 400px;
        }
    }
}

.dedicated__banner {
    max-width: 690px;
}

.dedicated-banner__image {
    .shape-image {
        &.one {
            top: 10px;
            right: 15px;
            left: unset;
            animation: rotateIt 12s linear infinite;
        }

        &.two {
            bottom: 15px;
            right: unset;
            left: 10px;
            top: unset;
            z-index: 1;
            animation: rotateIt2 12s linear infinite;
        }
    }
}

.wordpress-banner__image {
    .shape-image {
        &.one {
            top: -25px;
            right: 32%;
            left: unset;
            z-index: 1;

            @include mq($sm) {
                display: none;
            }
        }

        &.two {
            top: 35%;
            right: unset;
            left: 15px;
            bottom: unset;
            z-index: 1;

            @include mq($sm) {
                display: none;
            }
        }

        &.three {
            bottom: 12%;
            right: 10px;
            left: unset;
            top: unset;
            z-index: 1;

            @include mq($sm) {
                display: none;
            }
        }
    }
}

.reseller-banner__image {
    .shape-image {
        &.one {
            top: 0;
            left: -30px;
            right: unset;
            z-index: 1;
            animation: shake-br 12s linear infinite;
        }

        &.two {
            top: 50px;
            right: unset;
            left: 45px;
            bottom: unset;
            z-index: 1;
            animation: shake-tl 12s linear infinite;
        }

        &.three {
            bottom: 12%;
            right: 36%;
            left: unset;
            top: unset;
            z-index: -1;
        }
    }
}

.cloud__banner {
    max-width: 670px;
}

.resell__banner {
    max-width: 435px;
}

.affiliate__banner {
    max-width: 530px;
}

.affiliate__banner-image {
    border-radius: 6px;
    margin-right: 30px;

    img {
        border-radius: 6px;
    }

    &:before {
        position: absolute;
        left: 30px;
        top: -30px;
        height: 100%;
        width: 100%;
        content: '';
        border: 1px solid rgba(255, 255, 255, 0.20);
        border-radius: inherit;
        z-index: -1;
    }
}

.pricing__banner {
    max-width: 660px;
}

.black__friday-width {
    max-width: 480px;
}

.position-bg {
    position: relative;
}

.start-tour {
    color: var(--color-third);
    text-decoration: underline;
    text-decoration-color: var(--color-third);
    font-size: 18px;
    font-weight: 600;
    font-family: var(--font-secondary);
    margin-top: 20px;
    display: inline-block;
    transition: var(--transition);

    &:hover {
        text-decoration-color: var(--color-white);
        text-decoration: underline;
        color: inherit;
    }
}