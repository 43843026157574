// mobile menu style 



.side-bar {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: -100%;
    width: 465px;
    padding: 40px 30px;
    padding-top: 50px;
    height: 100%;
    display: block;
    background-color: white;
    backdrop-filter: blur(7px);
    z-index: 1900;
    transition: all 600ms ease;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow: visible;
    @media #{$large-mobile} {
        width: 300px;
    }
    .inner-main-wrapper-desk{
        .thumbnail{
            display: flex;
            justify-content: center;
            img{
                width: 85%;
                margin: auto;
            }
        }
        .inner-content{
            text-align: center;
            margin-top: 30px;
            p{
            max-width: 95%;
            text-align: center;
            margin: auto;
            }
            .title{
                font-weight: 600;
            }
            .footer{
                padding-top: 50px;
                margin-top: 80px;
                border-top: 1px solid #c2c2c2;
                .title{
                    font-weight: 500;
                }
                a.rts-btn{
                    margin: auto;
                }
            }
        }
    }
}
.side-bar.show {
    right: 0;
    overflow-y: auto;
}
.side-bar button {
    max-width: max-content;
    margin-right: auto;
    margin-left: -53px;
    margin-top: 0;
    position: absolute;
    border: none;
    i{
        color: #ffffff;
        height: 50px;
        width: 50px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        margin-top: -53px;
        font-size: 27px; 
        background: var(--color-primary);   
        font-weight: 300;
    }
}

#anywhere-home {
    cursor: url(../images/close.png), auto;
    background: #0e1013;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
    z-index: 50;
}

#anywhere-home.bgshow {
    background: #0e1013;
    opacity: 70%;
    visibility: visible;
    pointer-events: visible;
    z-index: 999;
    top: 0;
}

#menu-btn{
    cursor: pointer;
    padding: 10px;
    display: none;
    @media #{$md-layout} {
        display: block;
    }
    @media #{$sm-layout} {
        display: block;
    }
}

// mobile menu active

#side-bar{
    .mobile-menu-main{
        display: none;
        @media #{$md-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: block;
        }
    }
}

// mobile menu scss
.mobile-menu-main{
    nav{
        ul{
            padding: 0 20px;
            display: block;
            li{
                margin: 0;
                padding: 0;
                a.main{
                    padding: 12px 0 17px 0;
                    border-bottom: 1px solid #f3f3f3;
                    cursor: pointer;
                }
                &.has-droupdown{
                    position: relative;
                    ul{
                        padding: 0;
                        a{
                            padding: 10px 0;
                        }
                        a.tag{
                            font-weight: 700;
                            margin-top: 15px;
                            font-size: 18px;
                            border-bottom: 2px solid var(--color-primary);
                            padding: 10px 0;
                        }
                        li{
                            margin: 7px 0 !important;
                            border-bottom: 1px solid #f3f3f3;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '\f054';
                        font-family: 'Font Awesome 6 pro' !important;
                        font-size: 16px;
                        right: 0;
                        font-weight: 400;
                        top: 5px;
                        padding: 8px 13px;
                        color: var(--color-title);
                        color: rgba(38, 38, 44, .5);
                        pointer-events: none;
                        cursor: pointer;
                    }
                    &.mm-active{
                        &::after{
                            content: '\f077';
                        }
                    }
                    &.third-lvl{
                        &::after{
                            font-size: 10px;
                            padding: 3px 10px;
                        }
                        ul{
                            padding: 0 20px;
                            li{
                                margin: 10px 0 !important;
                                position: relative;
                                z-index: 1;
                                transition: all .3s;
                                &:hover{
                                        color: var(--color-primary);
                                }
                                a{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    transition: all .3s;
                                }
                            }
                        }
                    }
                }
                a{
                    display: block;
                }
            }
        }
    }
    .social-area-one{
        margin-top: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin: 40px 0 0;
        gap: 30px;
        li{
            list-style: none;
        }
    }
}

// header-two menu are-start
.side-bar{
    &.header-two,
    &.header-three{
        .inner-main-wrapper-desk{
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .mobile-menu{
            @media screen and (min-width: 1024px) {
                display: none !important;
            }
        }
    }
}

.header-one .main-nav-desk nav ul li.has-droupdown .submenu.inner-page{
    .sub-dropdown{
        position: relative;
        &:hover{
            &::after{
                color: var(--color-primary);
            }
        }
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
        }
        .third-lvl{
            margin-left: -4px;
        }
    }
}
.header-three .main-nav-desk nav ul li.has-droupdown .submenu.inner-page{
    padding: 15px 0;
    .sub-dropdown{
        position: relative;
        &:hover{
            &::after{
                color: var(--color-primary);
            }
        }
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
        }
        .third-lvl{
            margin-left: -4px;
        }
    }
    li{
        margin: 0;
        width: 100%;
        a{
            display: block;
            width: 100%;
            padding: 0 15px;
        }
    }
}


.sub-dropdown{
    position: relative !important;
    display: block !important;
    .submenu.third-lvl{
        opacity: 0 !important;
        min-width: 185px !important;
        left: 100% !important;
        top: -13% !important;
        margin: 0;
        border-radius: 0 !important;
        &.base{
            display: block !important;
        }
    }
    &:hover{
        .sub-menu-link{
            color: var(--color-primary);
        }
        .submenu.third-lvl.base{
            opacity: 1!important;
            min-width: 185px !important;
            top: 0 !important;
            right: 3px;
            display: block !important;
            li{
                display: block;
                a{
                    display: block !important;
                }
            }
        }
    }
}

.header-three{
    .sub-dropdown:hover .submenu.third-lvl.base{
        margin-left: -14px !important;
    }
}
.header-two .header-main-wrapper{
    .sub-dropdown:hover .submenu.third-lvl.base{
        margin-left: 90px !important;
    }
}

header.heder-two{
    .sub-dropdown:hover .submenu.third-lvl.base{
        opacity: 1 !important;
        min-width: 185px !important;
        top: 0 !important;
        right: 3px;
        display: block;
        margin-left: 0 !important;
    }
    .sub-dropdown{
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 20px;
            top: 8px;
            color: #fff;
        }
        &:hover{
            a.sub-menu-link{
                color: var(--color-primary) !important;
            }
            &::after{
                color: var(--color-primary) !important;
            }
        }
    }
    .sub-dropdown .submenu.third-lvl.base{
        display: block;
    }
}

.sub-dropdown{
    position: relative !important;
    display: block !important;
    transition: all .3s ease-in-out;
    &.active{
        .sub-menu-link{
            color: #FFFFFF !important;
            padding-left: 40px !important;
            &::after{
                width: 12px !important;
            }
        }
        &::after{
            color: #FFFFFF;
        }
    }
    .fluxi-submenu.third-lvl{
        opacity: 0 !important;
        min-width: 200px !important;
        left: 100% !important;
        top: -13% !important;
        margin: 0;
        transform: translateY(25px) !important;
        transition: all .3s ease-in-out;
        pointer-events: none;
        display: block;
        &:hover{
            li{
                a{
                    opacity: .5;
                }
            }
        }
        &.base{
            visibility: hidden;
            transition: all .3s ease-in-out;
        }
        li{
            display: block;
            margin: 0;
            position: relative;
            width: 100%;
            &:hover{
                a{
                    background: #F2F2F8;
                    opacity: 1;
                    &::after{
                        width: 12px;
                    }
                }
            }
            a{
                display: block !important;
                padding: 12px 0px 12px 15px;
                position: relative;
                font-size: 14px;
                color: #26262C !important;
                font-weight: 600;
                text-align: left;
                border-radius: 10px;
            }
        }
    }
    &:hover{
        &::after{
            color: #FFFFFF;
        }
        .sub-menu-link{
            color: var(--color-primary);
        }
        .fluxi-submenu.third-lvl.base{
            visibility: visible;
            opacity: 1!important;
            min-width: 200px !important;
            top: 0 !important;
            right: 3px;
            transform: translateY(5px) !important;
            pointer-events: all;
        }
    }
}