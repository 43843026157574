.rts-blog-list {
    .single-post {
        border-radius: 10px;
        overflow: hidden;
        background: var(--color-white);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
        margin-bottom: 60px;

        a {
            display: inline-block;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &__meta {
            padding: 40px 50px 50px 50px;

            .author__date__cat {
                margin-bottom: 30px;
                display: flex;
                gap: 30px;
                color: #74787C;
                line-height: 100%;
                flex-wrap: wrap;

                a {
                    color: #74787C;
                    transition: var(--transition);
                    text-transform: capitalize;

                    &:hover {
                        color: var(--color-primary);
                    }
                }

                i {
                    color: var(--color-secondary);
                    margin-right: 10px;
                }
            }

            .title {
                font-size: 34px;
                color: var(--color-secondary);
                line-height: var(--lh-h3);
                transition: var(--transition);
                width: 95%;
                position: relative;

                @include mq($xs) {
                    width: 100%;
                }

                margin-bottom: 25px;

                &:hover {
                    color: var(--color-primary);
                }
            }

            .excerpt {
                font-family: var(--font-secondary);
                margin-bottom: 40px;
                line-height: 26px;
            }

            .readmore__btn {
                max-width: 180px;
                text-align: center;
            }
        }
    }
}

// BLOG SIDEBAR CSS
.rts-sidebar {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: sticky;
    top: 120px;

    .rts-single-widget {
        padding: 35px 30px;
        border-radius: 6px;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);

        .widget-title {
            color: var(--color-secondary);
            font-weight: 700;
            margin-bottom: 30px;
            text-transform: capitalize;
        }

        &.search-widget {
            form {
                position: relative;

                input {
                    padding: 15px 20px;
                    border-radius: 4px;
                    background: #F6F6F6;
                    position: relative;
                    transition: var(--transition);
                    line-height: 100%;

                    &:focus {
                        border-color: var(--color-third);
                    }
                }

                button {
                    position: absolute;
                    width: auto;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);

                }
            }
        }

        &.category-widget {
            .cat__counter {
                counter-reset: cat-counter;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .single-cat {
                padding: 16px 20px;
                border-radius: 4px;
                border: 1px solid var(--light-border);
                counter-increment: cat-counter;
                display: flex;
                gap: 10px;
                background: var(--color-white);
                transition: var(--transition);
                line-height: 100%;

                &:hover {
                    background: var(--color-primary);

                    a,
                    .button-tab {
                        color: var(--color-white);
                    }

                    &::before {
                        color: var(--color-white);
                    }
                }

                &:before {
                    content: counter(cat-counter) ".";
                    font-family: var(--font-secondary);
                    font-weight: 600;
                    font-size: 16px;
                    transition: var(--transition);
                }

                a {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-family: var(--font-secondary);
                    font-weight: 600;
                    color: var(--color-secondary);
                    transition: var(--transition);
                }

                .button-tab {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-family: var(--font-secondary);
                    font-weight: 600;
                    color: var(--color-secondary);
                    transition: var(--transition);
                    line-height: 1;

                }

            }
        }

        &.recentpost-widget {
            .recent-posts {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .single-post {
                    display: flex;
                    gap: 20px;
                    align-items: start;
                }

                .thumb {
                    img {
                        min-height: 85px;
                        min-width: 85px;
                        max-width: 85px;
                        border-radius: 4px;
                    }
                }

                .meta {
                    .published {
                        color: #74787C;
                        font-size: 14px;
                        margin-bottom: 16px;
                        display: inline-block;
                        line-height: 1;

                        i {
                            margin-right: 5px;
                        }
                    }

                    .title {
                        margin-bottom: 0;

                        a {
                            color: var(--color-secondary);
                            font-weight: 600;
                            transition: var(--transition);

                            &:hover {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }

        &.gallery-widget {
            .gallery-posts {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 15px;

                .thumb {
                    img {
                        min-width: 95px;
                        max-width: 95px;
                        min-height: 95px;
                        border-radius: 4px;

                        @include mq($lg) {
                            min-width: 130px;
                        }
                    }
                }
            }
        }

        &.tags-widget {
            .popular-tags {
                .tags-style {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                }

                .tags {
                    transition: var(--transition);

                    a {
                        border: 1px solid var(--light-border);
                        padding: 10px 20px;
                        border-radius: 3px;
                        display: inline-block;
                        background: var(--color-white);
                        transition: var(--transition);
                        text-transform: capitalize;
                        line-height: 100%;
                        font-size: 14px;

                        &:hover {
                            background: var(--color-primary);
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}

// BLOG DETAILS PAGE
.blog-details {
    margin-bottom: 50px;
    &__featured-image {
        img {}
    }

    &__article-meta {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        a {
            span {
                i {
                    margin-right: 10px;
                }
            }
        }

        span {
            i {
                margin-right: 10px;
            }
        }
    }

    .blog-title {
        font-weight: 600;
    }

    .rts-blockquote {
        border-radius: 3px;
        background: #F6F6F6;
        padding: 30px;
        text-align: center;

        h4 {
            margin-bottom: 10px;
            font-weight: 600;
        }

        span {
            text-decoration: underline;
        }
    }

    .post-image {
        height: 270px;
        object-fit: cover;
        overflow: hidden;
        border-radius: 4px;
        margin-top: 30px;
    }

    .check-area-details {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .single-check {
            i {
                margin-right: 5px;
            }
        }
    }
}

.blog-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    .blog-tags {
        display: flex;
        gap: 10px;

        .tags-title {
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 600;
        }

        &__list {
            display: flex;
            gap: 10px;

            a {
                border: 1px solid var(--light-border);
                padding: 10px 15px;
                border-radius: 3px;
                display: inline-block;
                background: var(--color-white);
                transition: var(--transition);
                text-transform: capitalize;
                line-height: 100%;
                font-size: 14px;

                &:hover {
                    background: var(--color-primary);
                    color: var(--color-white);
                }
            }
        }
    }

    .blog-share {
        display: flex;
        gap: 10px;

        .share {
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.blog-author {
    margin-top: 40px;
    border-top: 1px solid var(--light-border);
    border-bottom: 1px solid var(--light-border);

    &__info {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 40px 0;

        .author-image {
            height: 170px;
            width: 170px;
            overflow: hidden;
            border-radius: 4px;
        }

        .author-content {
            a {
                font-size: 20px;
                font-weight: 600;
                color: var(--color-secondary);
                font-family: var(--font-secondary);
                margin-bottom: 10px;
                display: block;
            }

            p {
                max-width: 350px;
            }
        }
    }
}

.blog-comment {
    margin-top: 40px;

    &__template {
        h4 {
            font-weight: 600;
            color: var(--color-secondary);
            margin-bottom: 30px;
        }

        .comment-template {

            input,
            textarea {
                border: 1px solid var(--light-border);
                border-radius: 3px;
                padding: 10px 15px;
            }

            .input-area {
                display: flex;
                gap: 15px;
                margin-bottom: 20px;
                @include mq($xs){
                    flex-wrap: wrap;
                }
            }

            .input-area-full {
                margin-bottom: 20px;

            }

            textarea {
                min-height: 200px;
                resize: none;
            }
        }
    }
}