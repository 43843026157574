// HOSTIE SLIDER PAGINATION BUTTON STYLE.
.rts-slider__btn {
    display: flex;
    gap: 10px;

    .slide__btn {
        transition: all .3s ease-in;
        height: 40px;
        width: 40px;
        border-radius: 10px;
        border: 1px solid var(--border-color);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: var(--color-primary);
            border-color: var(--color-primary);
            color: var(--body-bg);
        }
    }
}

.rts-slider__btn__two {
    display: flex;
    gap: 10px;

    .slide__btn {
        transition: all .3s ease-in;
        height: 50px;
        width: 50px;
        border-radius: 6px;
        border: 1px solid #147BFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 2;
        color: var(--color-white);

        &:hover {
            background: var(--color-third);
            border-color: #147BFF;
            color: var(--color-secondary);
        }
    }
}

// HOSTIE PAGINATION DOT STYLE
.rts-dot__button {
    display: flex;
    gap: 10px;
    margin-top: 50px;

    & .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 1px solid var(--border-color);
        background: transparent;
        opacity: 1;
        margin: 0;
        transition: all .3s ease;

        &.swiper-pagination-bullet-active {
            background: var(--color-secondary);
            border-color: var(--color-secondary);
        }
    }

    &.slider-center {
        justify-content: center;
    }
}

// HOSTIE PAGINATION ARROW STYLE full width
.slider-arrow .rts-slider-btn{
    position: absolute;
    top: 50%;
    background: transparent;
    height: 80px;
    width: 80px;
    border: 1px solid #6D6D6D;
    border-radius: 50%;
    color: var(--color-white);
    left: 60px;
    z-index: 9;
    transform: translateY(-50%);
    font-size: 24px;
    transition: var(--transition);
    &.swiper-btn-prev{
        left: auto;
        right: 60px;
    }
    &:hover{
        background: var(--color-third);
        border-color: var(--color-third);
        color: var(--color-secondary);
    }
    @include mq($md){
        height: 60px;
        width: 60px;
        font-size: 20px;
    }
    @include mq($sm){
        display: none;
    }
}

.slider-dots {
    text-align: center;
    text-align: center;
    position: absolute;
    transform: translate(0);
    z-index: 1;
    display: none;
    @include mq($sm){
        display: block;
    }
    .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: var(--color-white);
        opacity: .8;
        position: relative;
        bottom: 30px;

        &.swiper-pagination-bullet-active {
            background: var(--color-third);
            border-color: var(--color-secondary);
        }
    }
}
// HOSTIE SECTION AREA.
.rts-section {
    margin-bottom: 50px;

    &__title {
        font-weight: var(--bold);
        color: var(--color-secondary);
        display: inline-block;
        margin-bottom: 20px;
        letter-spacing: -.5px;
        & br{
            @include mq($xs){
                display: none;
            }
            
        }
    }

    &__description {
        font-family: var(--font-secondary);
        font-weight: var(--regular);
        color: var(--btc);
        margin: 0 auto;
        @include mq($sm){
            width: 100%;
        }
    }

    &.section-style-two {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        @include mq($md){
            align-items: center;
            flex-wrap: wrap;
            gap: 30px;
        }
        @include mq($xs){
            gap: 20px;
        }
    }

    &__two {
        max-width: 510px;
        @include mq($md){
            width: 100%;
        }
        .sub__title {
            padding: 8px 14px;
            border-radius: 3px;
            background: var(--body-bg);
            color: var(--color-secondary);
            line-height: 100%;
            display: inline-block;
            margin-bottom: 10px;
            text-transform: capitalize;
            font-size: 15px;
            font-weight: var(--medium);
        }

        .title {
            font-weight: var(--bold);
            color: var(--color-secondary);
            display: block;
            line-height: 1.3;
            margin-bottom: 25px;
            @include mq($xs){
                text-align: left;
            }
            br{
                @include mq($sm){
                    display: none;
                    line-height: 1;
                }
            }
        }

        .description {
            font-family: var(--font-secondary);
            width: 95%;

        }

        .description p {
            font-family: var(--font-secondary);

        }


    }

    .rts-section-description {
        p {
            margin-bottom: 0;
            font-family: var(--font-secondary);
            @include mq($xs){
                text-align: left;
            }
        }
    }
}
.rts-section-button a{
    i{
        margin-left: 10px;
    }
}


// HOSTIE PAYMENT METHOD STYLE
.payment__method,
.social__media {
    h5 {
        font-family: var(--font-secondary);
        color: var(--color-secondary);
        font-weight: var(--semibold);
        margin-bottom: 20px;
        text-transform: capitalize;
    }

    ul {
        @include ul;
        display: flex;
        gap: 5px;

        li {
            img {
                height: 30px;
                width: 50px;
                object-fit: cover;
                overflow: hidden;
                border-radius: 4px;
            }
        }
    }
}

// SOCIAL MEDIA STYLE
.social__media {
    &--list {
        display: flex;
        gap: 10px;

        .media {
            height: 34px;
            width: 34px;
            border-radius: 4px;
            border: 1px solid #D4DCFF;
            display: inline-flex !important;
            justify-content: center;
            align-items: center;
            background: transparent;
            transition: all .3s ease-in;
            font-size: 14px !important;

            &:hover {
                background: var(--color-primary);
                border-color: var(--color-primary);
                color: var(--body-bg);
            }
        }
    }
}

// SEPARATOR CSS
.separator {
    margin: 25px 0;
}

.site-default-border {
    border-top: 1px solid var(--border-color);
}

.border__bottom {
    transition: all .3s ease;

    &:hover {
        text-decoration: underline;
    }
}
.home-three .rts-section__description {
	width: 100%;
}
.no-bg{
    background: #fff !important;
}
.transparent{
    background: transparent !important;
}
.rts-footer.footer-style-default {
	padding-top: 120px;
	padding-bottom: 80px;
    @include mq($md){
        padding-bottom: 30px !important;;
    }
}