// back to top style scss
.progress-wrap {
	position: fixed;
	right: 30px;
	bottom: 30px;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	z-index: 8;
	opacity: 1;
	// visibility: hidden;
	// transform: translateY(15px);
	// -webkit-transition: all 100ms linear;
    // transition: all 100ms linear;
	opacity: 0 !important;
	-webkit-transform: translateY(100%);
			transform: translateY(100%);
	-webkit-transition: opacity 0.3s var(--ease-in-out-4), -webkit-transform 0.3s var(--ease-in-out-4);
	transition: opacity 0.3s var(--ease-in-out-4), -webkit-transform 0.3s var(--ease-in-out-4);
	transition: transform 0.3s var(--ease-in-out-4), opacity 0.3s var(--ease-in-out-4);
	transition: transform 0.3s var(--ease-in-out-4), opacity 0.3s var(--ease-in-out-4), -webkit-transform 0.3s var(--ease-in-out-4);
	--ease-in-out-4: cubic-bezier(0.7, 0, 0.3, 1);
}

.progress-wrap.active-progress {
	opacity: 1 !important;
	visibility: visible;
	transform: translateY(0);
}
.progress-wrap::after {
	position: absolute;
	font-family:var(--fontawesome);
	content: '\f062';
	text-align: center;
	line-height: 46px;
	font-size: 18px;
	color: var(--color-primary);
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	z-index: 1;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    border: 2px solid var(--color-primary);
    box-shadow: none;
	border-radius: 50% !important;
	border-radius: 5px;
}
.progress-wrap:hover::after {
	opacity: 1;
    content: '\f062';
    border: 2px solid var(--color-primary);
}
.progress-wrap::before {
	position: absolute;
	font-family:var(--fontawesome);
	content: '\f062';
	text-align: center;
	line-height: 46px;
	font-size: 18px;
	opacity: 0;
	background: var(--color-primary);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	z-index: 2;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap:hover::before {
	opacity: 0;
}
.progress-wrap svg path { 
	fill: none; 
}
.progress-wrap svg { 
    color: var(--color-primary);
	border-radius: 50%;
	background: #fff;
}
.progress-wrap svg.progress-circle path {
    stroke: var(--color-primary);
    stroke-width: 34px;
	box-sizing:border-box;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}



.home-blue{
	.progress-wrap svg.progress-circle path{
		stroke: var(--color-primary-2);
	}
	.progress-wrap::after{
		border-color: var(--color-primary-2);
		box-shadow: 0px 3px 20px 6px #0742e952;
		color: var(--color-primary-2);
	}
}

